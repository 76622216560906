.carousel .control-next.control-arrow:before { border-left: 10px solid #000; }


.carousel .control-prev.control-arrow:before {
    border-right: 10px solid #000;
}


.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity = 30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}